import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Layout from "components/Layout";
import SEO from "components/Seo";

const SuccessPage = () => (
  <Layout>
    <SEO title="Order Success" />
    <section>
      <Container>
        <h2>Order Success!</h2>
        <Row>
          <Col xs={12}>
            <p className="font-weight-bold">
              Your payment was successful. Your email inbox will receive
              purchase receipt(s) in a moment, for your record keeping.
            </p>

            <p>
              Note: Be sure to save your email! We have decided to disable the
              customer login and dashboard functionality, because too many
              people were unable to follow our secure account creation
              instructions.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
);

export default SuccessPage;
